import React, { useEffect, useState } from 'react';

import logo from './assets/images//compressed/logo.png';
import mapDesktop from './assets/images/compressed/map_desktop.png';
import mapMobile from './assets/images/compressed/map_mobile.png';
import backgroundImg from './assets/images/compressed/bg_su_burbulu.jpg';

import phoneIcon from './assets/images/phone.png';
import mailIcon from './assets/images/mail.png';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
      <div className='mainpage-container'>
        <div className='mainpage-container__header-container'>
          <img
            src={logo}
            className='mainpage-container__header-container__logo'
            alt='autolaja-logo'
          />
          <h1 className='mainpage-container__header-container__text'>
            Profesionalios automobilių remonto paslaugos,
            {width >= 700 ? <br /> : <></>} diagnostika ir sezoniniai transporto
            priemonės priežiūros darbai
          </h1>
        </div>
        <div className='mainpage-container__main-container'>
          <div className='mainpage-container__main-container__contact-text'>
            Susisiekite su mumis:
          </div>
          <div className='mainpage-container__main-container__contact-phone'>
            <img
              className='mainpage-container__main-container__contact-phone__icon'
              src={phoneIcon}
              alt='autoloja-phone'
            />
            <a
              className='mainpage-container__main-container__contact-phone__link'
              href='tel:+37060743323'
            >
              +370 607 433 23
            </a>
          </div>
          <div className='mainpage-container__main-container__contact-email'>
            <img
              className='mainpage-container__main-container__contact-email__icon'
              src={mailIcon}
              alt='autoloja-email'
            />
            <a
              className='mainpage-container__main-container__contact-email__link'
              href='mailto:autolaja.autoservisas@gmail.com'
            >
              autolaja.autoservisas@gmail.com
            </a>
          </div>
        </div>
        <div className='mainpage-container__main-container__map-container'>
          <div className='mainpage-container__main-container__map-container__address-text'>
            <p>
              Mus rasite
              <span className='mainpage-container__main-container__map-container__address-text__red'>
                Jankiškių g. 16B, Vilnius
              </span>
            </p>
            <a
              href='https://goo.gl/maps/XkBR4kuLLGRRsCdc7'
              target='_blank'
              rel='noreferrer noopener'
            >
              Žiūrėti žemėlapyje:
              <span className='mainpage-container__main-container__map-container__address-text__red'>
                54.6499404, 25.1959153
              </span>
            </a>
          </div>
          {width >= 700 ? (
            <div className='mainpage-container__main-container__map-container__map'>
              <img src={mapDesktop} alt='autolaja-map' />
            </div>
          ) : (
            <div className='mainpage-container__main-container__map-container__mobile-map'>
              <img src={mapMobile} alt='autolaja-map' />
            </div>
          )}
        </div>
        <div />
      </div>
      <img
        className='mainpage-container__background-image'
        src={backgroundImg}
        alt='autolaja-background'
      />
    </>
  );
}

export default App;
